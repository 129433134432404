//var $ = require('jquery');
window.$ = window.jQuery = require('jquery');
require('bootstrap-sass');
// var typeahead = require('bootstrap-3-typeahead');
// CommonJS
var swal = require('sweetalert2');

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});


$(document).ready(function() {
    if ($('.swiper-container')[0]) {
        var swiper = new Swiper('.swiper-container', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }

    $( ".collapse-menu li" ).click(function () {

        var menuElement = $(this).parent();

        if ($('.btn-action').attr('data-target')) {
            $("#loginModal").modal();
            // close menu
            menuElement.collapse('hide');
        } else {
            var parentElementId = menuElement.attr('id');
            var status = $(this).find('span').attr('class');
            // collapse-menu-
            var productId = parentElementId.replace('collapse-menu-', '');

            // close menu
            menuElement.collapse('hide');

            updateMenuStatus(status, this, productId, menuElement);
        }
    });

    $('#category-selector').click(function () {
        $('#side-nav').css('width', '100%');
    });

    $('#sidenav-close').click(function () {
        $('#side-nav').css('width', 0);
    });

    function updateMenuStatus(status, clickedElement, productId, menuElement) {

        var mainElement = $('a#' + productId);
        var mainElementWidth = mainElement.width() + 12;

        mainElement.css('width', mainElementWidth);
        // $('#' + productId + '  span').addClass('hide');
        mainElement.find('span').addClass('hide');
        $('#' + productId + ' .spinner').addClass('activated');

        // process the form
        $.ajax({
            type        : 'POST',
            url         : '/isIngelogd',
            dataType    : 'json',
            //encode          : true
        })

        // using the done promise callback
        .done(function(json) {
            if (json.loggedIn) {

                if (!$(clickedElement).hasClass('disabled')) {

                    if (status == 'remove') {
                        removeFromShelf(productId, menuElement);
                    } else {
                        insertToUserShelf(clickedElement, productId, status, menuElement);
                    }
                }
            } else {
               $("#loginModal").modal();
            }
        });
    }

    $( "div" ).on( "click", ".btn-action:not('.activated'):not('.loading'):not([data-target])", function() {

        $(this).addClass('loading');
        var productId = $(this).attr('id');
        var menuElement = $('#collapse-menu-' + productId);
        updateMenuStatus('want_to_read', this, productId, menuElement);

    });

    function removeFromShelf(productId, menuElement) {

        var mainElement = $('a#' + productId);
        var currentStatus = mainElement.find('span').html();

        swal({
            title: 'Verwijderen van je boekenplank?',
            text: "Je kunt het dan niet meer beheren!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ja, verwijderen!',
            cancelButtonText: 'Nee, annuleren!',
        }).then((function(result) {

         if (result.value) {

            removeFromUserShelf(productId);
            swal(
                'Verwijderd!',
                'Het boek is van je boekenplank verwijderd',
                'success'
            )

            menuElement.collapse('hide');
            mainElement.removeClass('activated');

            $('#' + productId + ' .spinner').removeClass('activated');

            mainElement.find('span').removeClass('hide');

            mainElement.css('width', 'auto');
            mainElement.find('span').html('Wil ik lezen');

            //reset menu
            menuElement.find('li').removeClass('disabled');
            menuElement.find('li.remove').addClass('hide');

        } else if (result.dismiss === swal.DismissReason.cancel) {

            mainElement.find('span').html(currentStatus);

            $('#' + productId + ' .spinner').removeClass('activated');

            mainElement.find('span').removeClass('hide');

            mainElement.css('width', 'auto');

        }
    }))
    }

    function removeFromUserShelf(productId)
    {
        var formData = {
            'product_id' : productId
        };

        $.ajax({
            type        : 'POST',
            url         : '/boekenplank/verwijderen',
            data        : formData, // our data object
            dataType    : 'json', // what type of data do we expect back from the server
        })
        // using the done promise callback
        .done(function(json) {

        });

    }

    function insertToUserShelf(clickedElement, productId, status, menuElement) {

        var mainElement = $('a#' + productId);

        var formData = {
            'product_id' : productId,
            'status' : status,
            'type' : $('input[type="hidden"][name="item-type"]').val()
        };

        $.ajax({
            type        : 'POST',
            url         : '/boekenplank/toevoegen',
            data        : formData, // our data object
            dataType    : 'json', // what type of data do we expect back from the server
        })
        // using the done promise callback
        .done(function(json) {

            if (json.error == false) {
                // menuElement.collapse('hide');

                mainElement.css('width', 'auto');
                mainElement.find('span').html(json.result);
                // show spinner
                mainElement.addClass('activated');
                $('#' + productId + ' .spinner').removeClass('activated');

                mainElement.find('span').removeClass('hide');
                // shows remove link
                menuElement.find('li').removeClass('hide disabled');
                // deactivate selected menu item
                menuElement.find('.' + status).parent().addClass('disabled');

                $(clickedElement).removeClass('loading');
            }
        });

        // stop the form from submitting the normal way and refreshing the page
        event.preventDefault();
    }

    // MENU
    $('#bars').click(function()
    {
        $('#menu-list').slideToggle('fast');
        return false;
    });

    $('.filter input[type="radio"]').click(function () {

        var searchterm = $('.filter input[type="hidden"][name="searchterm"]').val();
        var type = $(this).val();

        window.location.href = '/zoeken?type=' + type + '&zoekwoord=' + searchterm;
    });

    $('.filter-status input[type="radio"]').click(function () {

        var status = $(this).val();

        window.location.href = '/mijn-boekenplank?status=' + status;
    });


    //
    // $(window).scroll(function() {
    //         var sticky = $('.fixed-search'),
    //             top = $(window).scrollTop();
    //
    //         if (top >= 81) {
    //             sticky.addClass('fixed');
    //         }
    //         else {
    //            sticky.removeClass('fixed');
    //         }
    //
    //         //
    //         // var search = $(".fixed-search");
    //         // var offset = search.offset();
    //         // var sticky = offset.top;
    //         //
    //         // if (window.pageYOffset > sticky) {
    //         //     search.addClass("fixed");
    //         // } else {
    //         //     search.removeClass("fixed");
    //         // }
    // });

    // $(window).scroll(function(){
    //
    //     var sticky = $('.search'),
    //         top = $(window).scrollTop();
    //
    //     if (top >= 81)
    //        sticky.addClass('fixed');
    //     else
    //        sticky.removeClass('fixed');
    // });
    //
    // var $input = $(".typeahead");
    //
    // $input.typeahead({
    //
    //     minLength: 3,
    //     delay: 500,
    //     items: 10,
    //
    //     matcher: function (item) { // otherwise typeahead will filter extra
    //         return true;
    //     },
    //     source: function (query, process) {
    //
    //        var val = $.trim($input.val());
    //
    //        if (!val)
    //            return;
    //
    //        var employees = [];
    //
    //        $input.addClass('loader');
    //
    //        $.getJSON('/ebooks', {search: val}, function (data) {
    //
    //            // Loop through and push to the array
    //            $.each(data, function (i, e) {
    //                employees.push(e.name + "#" + e.author + "#" + e.id + "#" + e.img_xs_url);
    //            });
    //
    //            $input.removeClass('loader');
    //
    //            //console.log(data);
    //            return process(employees);
    //        });
    //     },
    //     highlighter: function (item) {
    //
    //         var parts = item.split('#'),
    //
    //         html =  '<div class="search-results">';
    //         html += '<div class="pull-left mr-small">';
    //         html +=     '<img height="40" src="' + parts[3] + '">';
    //         html += '</div>';
    //         html += '<div class="pull-left margin-small">';
    //         html +=     '<div class="text-left"><strong>' + parts[0] + '</strong></div>';
    //         html +=     '<span class="text-left author">' + parts[1] + '</span>';
    //         html +=     '<span class="text-left isbn"> ' + '(isbn: ' + parts[2] + ')' + '</span>';
    //         html += '</div>';
    //         html +=     '<div class="clearfix"></div>';
    //         html += '</div>';
    //         return html;
    //     }
    // });
    //
    // var searchValue = '';
    //
    // $input.change(function(event) {
    //     var current = $input.typeahead("getActive");
    //
    //     // fix for preventing typeahead filling the input with the selected data
    //     if (searchValue.length == 0) {
    //         searchValue = $input.val();
    //     } else {
    //         $input.val(searchValue);
    //     }
    //
    //     if (current) {
    //         var parts = current.split('#');
    //
    //         window.location.href = '/ebook/' + parts[2];
    //     } else {
    //         // Nothing is active so it is a new value (or maybe empty value)
    //     }
    // });

    checkCookieConsent();

    function checkCookieConsent()
    {
        var consent = getCookie("cookies_consent");

        if (consent == null || consent == "" || consent == undefined)
        {
            // show notification bar
            $('#cookieConsent').show();
        }

    }

    function setCookie(c_name,value,exdays)
    {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());
        document.cookie = c_name + "=" + c_value+"; path=/";

        $('#cookieConsent').fadeOut(300);
    }


    function getCookie(c_name)
    {
        var i,x,y,ARRcookies=document.cookie.split(";");
        for (i=0;i<ARRcookies.length;i++)
        {
            x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
            y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
            x=x.replace(/^\s+|\s+$/g,"");
            if (x==c_name)
            {
                return unescape(y);
            }
        }
    }

    $(".cookieConsentAffirm").click(function(){
        setCookie("cookies_consent", 1, 60);
    });
});
