window.$ = window.jQuery = require('jquery');

$(document).ready(function() {

    $('#user_login_btn').click(function () {

        var buttonText = $('#user_login_btn span').html();
        var buttonElement = this;
        $(buttonElement).prop('disabled', true);

        $('#user_login_btn span').html('');
        $('#user_login_btn div').removeClass('hide');

        var formData = {
            'username'          : $('input[name=login_username]').val(),
            'password'          : $('input[name=login_password]').val(),
            'remember_me'       : $('input[name=remember_me]').prop('checked')
        };

        // process the form
        $.ajax({
            type        : 'POST',
            url         : '/inloggen',
            data        : formData, // our data object
            dataType    : 'json', // what type of data do we expect back from the server
            //encode          : true
            beforeSend: function() {
                removeFieldErrors('login-form');
            }
        })

        // using the done promise callback
            .done(function(json) {
                if (json.errors === true) {

                    showFormErrors(json.fields, 'login');

                } else {
                    window.location.reload();
                }

                $(buttonElement).prop('disabled', false);
                $('#user_login_btn span').html(buttonText);
                $('#user_login_btn div').addClass('hide');
            });

        // stop the form from submitting the normal way and refreshing the page
        event.preventDefault();
    });

    $('#user_reset_btn').click(function () {

        var buttonText = $('#user_reset_btn span').html();
        var buttonElement = this;
        $(buttonElement).prop('disabled', true);

        $('#user_reset_btn span').html('');
        $('#user_reset_btn div').removeClass('hide');

        var email = $('input[name=reset_email]').val();

        var formData = {
            'email'          : email
        };

        // process the form
        $.ajax({
            type        : 'POST',
            url         : '/wachtwoordvergeten',
            data        : formData, // our data object
            dataType    : 'json', // what type of data do we expect back from the server
            //encode          : true
            beforeSend: function() {
                removeFieldErrors('lost-form');
            }
        })

        // using the done promise callback
            .done(function(json) {

                if (json.errors === true) {

                    showFormErrors(json.fields, 'lost');

                }
                else {

                    window.location.href = '/wachtwoordherstellen/' + email;
                    // window.location.reload();
                }
                //
                $(buttonElement).prop('disabled', false);
                $('#user_reset_btn span').html(buttonText);
                $('#user_reset_btn div').addClass('hide');
            });

        // stop the form from submitting the normal way and refreshing the page
        event.preventDefault();
    });

    function showFormErrors(fields, formType) {

        var form = '#' + formType + '-form';

        var firstErrorMessage = false;
        $.each(fields, function(fieldname, errorMessage) {
            $('#' + formType + '-' + fieldname + ' i').not('.fa-eye, .fa-eye-slash').addClass('error');

            if (!firstErrorMessage) {
                firstErrorMessage = errorMessage;
            }
        });

        $(form + ' .form-error').html(firstErrorMessage);

        if (!$(form + ' .form-error').is(':visible')) {
            var newHeight = $('.modal-dialog').height() + 30;

            $('.modal-content').stop(true, false).animate({
                height: newHeight
            });
            $(form + ' .form-error').slideDown();
        }
    }

    $('#save-new-user').click(function () {

        var buttonText = $('#save-new-user span').html();
        var buttonElement = this;
        $(buttonElement).prop('disabled', true);

        $('#save-new-user span').html('');
        $('#save-new-user div').removeClass('hide');

        var formData = {
            'username'              : $('input[name=username]').val(),
            'email'             : $('input[name=email]').val(),
            'password'          : $('input[name=password]').val(),
            'password_confirmation' : $('input[name=password_confirmation]').val(),
        };

        // process the form
        $.ajax({
            type        : 'POST',
            url         : '/registreren/nieuw',
            data        : formData, // our data object
            dataType    : 'json', // what type of data do we expect back from the server
            //encode          : true
            beforeSend: function() {
                removeFieldErrors('register-form');
            }
        })

        // using the done promise callback
            .done(function(json) {
                if (json.errors === true) {

                    showFormErrors(json.fields, 'register');
                    //
                    // $.each(json.fields, function(fieldname, errorMessage) {
                    //
                    //     showFieldError(fieldname, errorMessage);
                    // });

                } else {
                    // start mail process
                    sendConfirmationMail($('input[name=email]').val());
                }

                $(buttonElement).prop('disabled', false);
                $('#save-new-user span').html(buttonText);
                $('#save-new-user div').addClass('hide');

            });

        // stop the form from submitting the normal way and refreshing the page
        event.preventDefault();

    });

    $('#logout').click(function () {
        // process the form
        $.ajax({
            type        : 'POST',
            url         : '/uitloggen',
            dataType    : 'json',
            //encode          : true
        })

        // using the done promise callback
            .done(function(json) {
                window.location.reload();
            });

        // stop the form from submitting the normal way and refreshing the page
        event.preventDefault();
    });

    function removeFieldErrors(form) {
        // remove error element
        $("#" + form).find('.field-error').html('');
        $("#" + form).find('.error').removeClass('error');
    }

    function showFieldError(fieldname, text) {
        var errorField = $('#' + fieldname);
        // var inputField = $('input[name=' + fieldname + ']');

        errorField.html(text);
        errorField.removeClass('hidden');
        // inputField.addClass('error');
    }

    // Show Password
    $('#show-password').click(function()
    {
        if ($(this).hasClass('fa-eye'))
        {
            $('#register-password-field').attr('type', 'text');
            $(this).removeClass('fa-eye');
            $(this).addClass('fa-eye-slash');
        } else {
            $('#register-password-field').attr('type', 'password');
            $(this).removeClass('fa-eye-slash');
            $(this).addClass('fa-eye');
        }
    })

    var $formLogin = $('#login-form');
    var $formLost = $('#lost-form');
    var $formRegister = $('#register-form');
    var $divForms = $('#div-forms');
    var $modalAnimateTime = 300;
    var $msgAnimateTime = 150;
    var $msgShowTime = 2000;

    $(".login-form").submit(function () {
        switch(this.id) {
            case "login-form":
                var $lg_username=$('#login_username').val();
                var $lg_password=$('#login_password').val();
                if ($lg_username == "ERROR") {
                    msgChange($('#div-login-msg'), $('#icon-login-msg'), $('#text-login-msg'), "error", "glyphicon-remove", "Login error");
                } else {
                    msgChange($('#div-login-msg'), $('#icon-login-msg'), $('#text-login-msg'), "success", "glyphicon-ok", "Login OK");
                }
                return false;
                break;
            case "lost-form":
                var $ls_email=$('#lost_email').val();
                if ($ls_email == "ERROR") {
                    msgChange($('#div-lost-msg'), $('#icon-lost-msg'), $('#text-lost-msg'), "error", "glyphicon-remove", "Send error");
                } else {
                    msgChange($('#div-lost-msg'), $('#icon-lost-msg'), $('#text-lost-msg'), "success", "glyphicon-ok", "Send OK");
                }
                return false;
                break;
            case "register-form":
                var $rg_username=$('#register_username').val();
                var $rg_email=$('#register_email').val();
                var $rg_password=$('#register_password').val();
                if ($rg_username == "ERROR") {
                    msgChange($('#div-register-msg'), $('#icon-register-msg'), $('#text-register-msg'), "error", "glyphicon-remove", "Register error");
                } else {
                    msgChange($('#div-register-msg'), $('#icon-register-msg'), $('#text-register-msg'), "success", "glyphicon-ok", "Register OK");
                }
                return false;
                break;
            default:
                return false;
        }
        return false;
    });

    // $('#register').click( function () { modalAnimate($formRegister, $formLogin) });
    $('#login_register_btn').click( function () { modalAnimate($formLogin, $formRegister) });
    $('#register_login_btn').click( function () { modalAnimate($formRegister, $formLogin); });
    // $('#login_lost_btn').click( function () { modalAnimate($formLogin, $formLost); });
    $('#lost_login_btn').click( function () { modalAnimate($formLost, $formLogin); });
    // $('.forgot-password').click( function () { modalAnimate($formLost, $formLogin); });
    // $('#lost_register_btn').click( function () { modalAnimate($formLost, $formRegister); });
    $('#forgot_password_link').click( function () { modalAnimate($formLogin, $formLost); });
    // $('#register_lost_btn').click( function () { modalAnimate($formRegister, $formLost); });

    function modalAnimate ($oldForm, $newForm) {

        $newForm.find('.field-error').addClass('hidden');

        // var $oldH = $oldForm.height();
        // var $oldH = $divForms.height();

        var $oldH = $('.modal-content').height()-10;


        var $newH = $newForm.height();
        $divForms.css("height",$oldH);

        $('.modal-content').css('height', 'auto');
        $oldForm.fadeToggle($modalAnimateTime, function(){
            $divForms.animate({height: $newH}, $modalAnimateTime, function(){
                $newForm.fadeToggle($modalAnimateTime);
            });
        });
    }

    $('.modalEvent').click(function () {

        //$('#div-forms').find('.field-error').addClass('hidden');
        $('#div-forms').find('.form-error').hide();
        $('#div-forms').find('i').removeClass('error');

        var $formLogin = $('#login-form');
        var $formRegister = $('#register-form');
        var $formReset = $('#lost-form');
        var $divForms = $('#div-forms');
        var $modelContent = $('.modal-content');

        if ($(this).attr('id') == 'login-modal') {
            // $('#login-form').height();
            //var height = $('#login-form').height(); alert(height);
            //$divForms.css("height", height);
            $divForms.css("height", 316);
            $modelContent.css("height", 'auto');
            $formLogin.show();
            $formRegister.hide();
            $formReset.hide();
        } else {
            $divForms.css("height", 451);
            $modelContent.css("height", 'auto');
            $formReset.hide();
            $formLogin.hide();
            $formRegister.show();
        }
        $("#loginModal").modal()
    });

    $('.modal-dialog .close').click(function () {
        $("#loginModal").modal('hide');
    })

    function msgFade ($msgId, $msgText) {
        $msgId.fadeOut($msgAnimateTime, function() {
            $(this).text($msgText).fadeIn($msgAnimateTime);
        });
    }

    function msgChange($divTag, $iconTag, $textTag, $divClass, $iconClass, $msgText) {
        var $msgOld = $divTag.text();
        msgFade($textTag, $msgText);
        $divTag.addClass($divClass);
        $iconTag.removeClass("glyphicon-chevron-right");
        $iconTag.addClass($iconClass + " " + $divClass);
        setTimeout(function() {
            msgFade($textTag, $msgOld);
            $divTag.removeClass($divClass);
            $iconTag.addClass("glyphicon-chevron-right");
            $iconTag.removeClass($iconClass + " " + $divClass);
        }, $msgShowTime);
    }

    function sendConfirmationMail(emailAddress) {

        var formData = {
            'email' : emailAddress,
        }

        // process the form
        $.ajax({
            type        : 'POST',
            url         : '/registreren/verstuur-bevestigingsemail',
            data        : formData, // our data object
            dataType    : 'json', // what type of data do we expect back from the server
            //encode          : true
            // beforeSend: function() {
            //     removeFieldErrors('register-form');
            // }
        })        // using the done promise callback
        .done(function(json) {
            if (!json.errors) {
                window.location.href = '/registreren/bevestigingsemail-verstuurd/' + emailAddress;
            }
        });

        event.preventDefault();
    }
});